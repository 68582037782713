.article-content {
    font-family: 'akkurat';
    font-size: 1rem;
    line-height: 1.5;
  
    p {
      padding: 9px 0;
    }
  
    h1,
    h2,
    h3,
    h4,
    h5 {
      padding: 14px 0;
    }
  
    li {
      margin-left: 15px;
    }
  
    a {
      color: #ff7f50;
      transition: all 0.5s cubic-bezier(0, 0.5, 0.5, 1);
  
      &:hover {
        color: #801936;
        text-decoration: underline;
      }
    }
  
    img {
      max-width: 70%;
      height: auto;
  
      &::before {
        content: "";
        display: block;
        width: 40px;
        height: 1px;
        background-color: #333;
        transition: width 0.5s;
        left: 0;
      }
    }
  }
  
  
  .route-container {
    flex: calc(100% * (2 / 3));
    z-index: 11;
    border-left: #f6f6f6 solid 1px;

    @media screen and (max-width: 1000px) {
      margin-top: 30px;
      height: 400px;
      padding-bottom: 5px;
    }
  
    .image-container {
      text-align: center;
      max-height: 500px;
      overflow-y: hidden;
    }
  
    .html-container {
      width: 100%;
      padding: 2vw var(--bigbar) 5vw 2vw;
      background-color: white;
  
      //on mobile 100% width
      @media (max-width: 768px) {
        width: 100%;
        padding: 2vw 4vw 5vw 4vw;
        margin-right: 0;
      }
    }
  
    .loading-overlay {
      width: 0%;
      height: 100%;
      top: 0;
      position: absolute;
      background: #f6f6f6;
      z-index: 12;
      transition: all 0.5s cubic-bezier(0, 0.5, 0.5, 1);
      overflow: hidden;
    }
  
    .loading-overlay.loading {
      width: 100%;
      left: 0;
    }
  
    .loading-overlay.loaded {
      right: 0;
    }
  
    .loading-spinner {
      top: 20vw;
      left: 30.66vw;
      transform: translate(-50%, -50%);
      width: 80px;
      height: 2px;
      /* 
          background: #ccc; */
      position: absolute;
      overflow: hidden;
    }
  
    .loading-spinner::before {
      content: "";
      width: 80%;
      height: 100%;
      background: #971a43;
      position: absolute;
      top: 0;
      left: 0;
      animation: loop 1s cubic-bezier(0, 0.5, 0.5, 1) infinite;
    }
  }
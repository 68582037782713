.page-content {
    z-index: 11;

    .cards-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        font-family: akkurat, sans-serif;
    
        .officer-card {
            flex: 25%;
            max-width: calc(0.25 * (100vw - 8vw));
            padding: 12px 12px;
            border-left: 1px solid #f6f6f6;
            border-bottom: 1px solid #f6f6f6;
            background-color: white;
            position: relative;

            &:last-child {
                border-right: 1px solid #f6f6f6;
            }

            @media screen and (max-width: 768px) {
                flex: 100%;
                max-width: calc(100vw - 8vw);
                padding: 12px 0;
            }
    
            .card-top {
                position: relative;
                flex: 100%;

                &:hover {

                    .officer-info::before {
                        left: 0;
                        width: 100%;
                    }
                }
    
                .officer-info {
                    position: absolute;
                    bottom: 0;
                    color: rgb(32, 32, 32);
                    background: rgba(218, 96, 40, 0.5);/* 
                    max-width: calc(100vw - (2 * var(--smallbar))); */
                    width: 100%;
                    overflow: hidden;
                    display: flex;
                    flex-wrap: wrap;
                    padding: 5px 0;

                    &::before {
                        content: "";
                        display: block;
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        right: 0;
                        background: linear-gradient(rgba(218, 96, 40, 0.704), rgba(218, 96, 40, 0.704));
                        width: 0;
                        transition: width 0.5s;
                        z-index: 1;
                    }

                    .name {
                        padding: 0 12px;
                        font-size: 1.4em;
                        font-weight: 600;
                        color: #f6f6f6;
                        text-shadow: #858585 1px 1px 1px;
                        z-index: 2;
                    }

                    .position {
                        padding: 0 12px;
                        font-size: 1em;
                        font-weight: 400;
                        color: #f6f6f6;
                        z-index: 2;
                    }

                    .email {
                        padding: 0 12px;
                        font-size: 1em;
                        font-weight: 400;
                        color: #f6f6f6;
                        z-index: 2;
                    }

                    &>* {
                        flex: 100%;
                    }
                }
            }

            .card-bottom {
                flex: 100%;
                padding: 12px 12px;
                font-size: 1em;
                font-weight: 400;
                line-height: 1.5em;
                padding-bottom: 40px;
            }

            .mailto {
                bottom: 12px;
                position: absolute;
                display: flex;
                height: 30px;
                width: calc(100% - 24px);
                background-color: #801936;
                align-items: center;
                justify-content: center;

                @media screen and (max-width: 768px) {
                    width: 100%;
                }

                .mail {
                    text-decoration: none;
                    color: #f6f6f6;
                    
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}
@import url("https://fonts.googleapis.com/css?family=Lora:400,400i,700");

body {
    margin: 0;
}

.container {
    /* --bg: linear-gradient(-20deg, #fd660172 0%, #7e2d1c 100%); */
    --bg: linear-gradient(-20deg, #d6590672 0%, #7c2716 100%);

    position: relative;
    height: 100vh;
    background-image: var(--bg);
    animation: squeeze 1s ease-in-out forwards;
    animation-delay: 3.2s;

    @keyframes squeeze {
        0% {
            height: 100vh;
        }
        100% {
            height: 80vh;
        }
    }

    .slideshow {
        $slide-bgs: 
        url('https://i.imgur.com/IbOEzu7.jpg'),
        url('https://i.imgur.com/SDSA9wy.jpg'),
        url('https://i.imgur.com/dmlDE7L.jpg'),
        url('https://i.imgur.com/vAPljlf.jpg'),
        url('https://i.imgur.com/UtEwbtG.jpg'),
        //url('https://i.imgur.com/htohQqv.jpg');
        //url('https://i.imgur.com/15fvtLM.jpg');
        url('https://i.imgur.com/gRvXLGo.jpg');

        
        

        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        .slide {
            position: absolute;
            top: 0;
            left: 0;
            width: 0;
            height: 100%;
            overflow: hidden;
            animation: slide-right 0.6s ease-in-out forwards;
            filter:saturate(110%);
            filter: brightness(120%);
            filter: contrast(110%);
            z-index: 8;

            // use pseudo element to avoid the side effect of bg-size: cover
            &::after {
                position: absolute;
                content: '';
                top: 0;
                left: 0;
                width: 100vw;
                height: 100%;
            }

            @for $i from 1 through length($slide-bgs) {
                &:nth-child(#{$i}) {
                    animation-delay: 0.16s * $i;
                    @media only screen and (max-width: 600px){
                        animation-delay: 0.2s * $i;
                    }

                    &::after {
                        background: nth($slide-bgs, $i) center / cover no-repeat;
                    }
                }
            }
        }

        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-image: var(--bg);
            transform: translateX(-100%);
            animation: move-right .6s ease-in-out forwards;
            animation-delay: 0.15s * length($slide-bgs) + 1s;
            z-index: 8;

            h1 {
                font-size: 250%;
                font-family: Lora, serif;
                letter-spacing: 0.2em;
                color: white;
                text-shadow: 0 2px 2px black;
                opacity: 0;
                animation: fade-in 1s forwards;
                animation-delay: 0.15s * length($slide-bgs) + 1.7s;
            }

            img {
                opacity: 0;
                animation: fade-in 1s forwards;
                animation-delay: 0.15s * length($slide-bgs) + 1.7s;
                max-height: 40vh;
                width: auto;
                max-width: 80vw;
                height: auto;
            }
        }
    }
}

@keyframes slide-right {
    to {
        // If you animate scaleX here, the image will look weird. So animate width instead.
        // (a little damage on performance though)
        width: 100%;
    }
}

@keyframes move-right {
    to {
        transform: translateX(0);
    }
}

@keyframes fade-in {
    to {
        opacity: 1;
    }
}
.recent-articles {
    margin: 0 var(--smallbar);
    display: flex;
    height: fit-content;
    position: relative;
    font-family: 'ITCAvantGarde', sans-serif;
    flex-wrap: wrap;

    .first-article {
        flex: 50%;
        text-decoration: none;
        color: black;

        //slide up 50px on load
        transform: translateY(0);
        animation: slide-up 0.5s ease-in-out 0.5s forwards;
        animation-delay: 3.2s;
        z-index: 9;
        height: fit-content;

        @keyframes slide-up {
            0% {
                transform: translateY(0);
            }

            100% {
                transform: translateY(-70px);
            }
        }

        @media screen and (max-width: 1000px) {
            flex: 100%;
        }

        &:hover {
            .recent-article-title {
                color: var(--maroon);
            }
        }

        .image-container {

            width: calc((2 * var(--bigbar)) + var(--smallbar));
            height: calc((2 * var(--bigbar)));
        }

        .article-info-container {
            position: absolute;
            right: 0;
            background-color: white;
            z-index: 1;
            padding: 2.1063717746vw 2.1063717746vw 2.1063717746vw 2.1063717746vw;
            transform: translateY(-90px);
            font-size: 1.6rem;
            width: calc(2 * var(--bigbar));

            .article-info-header {
                padding-bottom: 17px;
                display: flex;
                font-size: 0.9rem;

                .recent-article-category {
                    position: absolute;
                    padding-left: 60px;
                    color: var(--maroon);
                }

                .recent-article-date {
                    color: gray;
                }
            }

            .recent-article-title {
                font-weight: bold;
                font-size: 2rem;
                padding-bottom: 20px;
                transition: all 0.5s ease-in-out;
            }

            .read-more {
                color: gray;
                font-size: 0.9rem;
            }
        }
    }

    .small-articles {
        flex: 50%;
        display: flex;
        flex-wrap: wrap;
        z-index: 11;
        border-top: 1px solid #f6f6f6;

        @media screen and (max-width: 1000px) {
            flex: 100%;
        }

        .small-article {
            flex: 100%;
            display: flex;
            background-color: white;
            border-bottom: 1px solid #f6f6f6;
            position: relative;
            cursor: pointer;
            transition: all 0.15s ease-in-out;
            text-decoration: none;
            color: black;

            &:hover {
                background-color: #f6f6f6;

                .image-container {
                    .recent-article-image {
                        filter: brightness(0.5);
                    }
                }

                .article-info-container {
                    .recent-article-title {
                        color: var(--maroon);
                    }
                }
            }

            .image-container {
                width: var(--bigbar);
                height: var(--bigbar);
                min-width: var(--bigbar);
                min-height: var(--bigbar);

                .recent-article-image {
                    transition: all 0.5s ease-in-out;
                    filter: brightness(0.8);
                }
            }

            .article-info-container {
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                padding: 2.1063717746vw 2.1063717746vw 2.1063717746vw 2.1063717746vw;

                @media screen and (max-width: 1000px) {
                    padding: 1.5vw 6vw 1.5vw 6vw;
                }

                .article-info-header {
                    display: flex;
                    padding-bottom: 17px;
                    font-size: 0.8rem;
                    
                    @media screen and (max-width: 1000px) {
                        font-size: 0.7rem;
                    }

                    .recent-article-date {
                        position: absolute;
                        color: gray;
                    }

                    .recent-article-category {
                        position: absolute;
                        padding-left: 60px;
                        color: var(--maroon);

                        @media screen and (max-width: 1000px) {
                            padding-left: 40px;
                        }
                    }
                }

                .recent-article-title {
                    font-weight: bold;
                    font-size: 1.2rem;
                    transition: all 0.5s ease-in-out;

                    @media screen and (max-width: 1000px) {
                        font-size: 1rem;
                    }
                }

                .read-more {
                    color: gray;
                    font-size: 0.8rem;
                }
            }
        }

        .news-link {
            flex: 100%;
            display: flex;
            justify-content: right;
            height: 70px;
            align-items: center;
            width: 100px;
            padding-right: 130px;
            text-transform: uppercase;
            font-size: 0.8rem;
            cursor: pointer;
            transition: all 0.15s ease-in-out;
            text-decoration: none;
            color: black;
            border-bottom: 1px solid #f6f6f6;

            &::before {
                content: '';
                display: inline-block;
                width: 7px;
                height: 1px;
                background-color: gray;
                padding-right: 20px;
                margin-right: 5px;
            }

            &:hover {
                color: #971a43;

                &::before {
                    background-color: #971a43;
                }
            }
        }
    }
}
.wrapper {
    display: flex;
    height: calc(100vh - 60px);
    width: 100%;
    bottom: 0;
    position: fixed;
    z-index: 11;
    flex-wrap: wrap;

    .headerWrapper {
        order: 1;
        width: 100%;
        height: 50px;
        flex: 100%;
        background-color: white;
        border-bottom: rgba(142, 142, 142, 0.43) solid 1px;
        font-size: 2.1rem;
        display: flex;
        justify-content: center;
        padding-top: 7px;
        /* TODO, center the text properly */
    }

    .menuWrapper {
        order: 2;
        flex: 15%;
        background-color: white;
        font-size: 1rem;
        border-right: rgba(142, 142, 142, 0.43) solid 1px;

        .menuTitle {
            text-align: center;
            justify-content: center;
            padding-top: 15px;
            padding-bottom: 5px;
        }
    }

    .planWrapper {
        order: 3;
        flex: 85%;
        width: fit-content;
        height: calc(100vh - 110px);
    }
}

.training-extras {
    // no bullet points
    list-style-type: none;

    .extras-section {}
}

.tables {

    .distance-table {
        margin-bottom: 30px;

        
            tr {
                &:first-child {
                th {
                    background-color: #971a43;
                    text-align: left;
                    color: #f3f3f3;
                }
            }
        }

        tr {

            th {
                background-color: #cfcfcf;
                text-align: left;
                color:black;
            }
        }
    }
}
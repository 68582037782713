.selector-container {
    width: 100vw;
    height: calc(100vh - 60px);
    bottom: 0;
    z-index: 98;
    display: flex;
    flex: 100%;
    flex-wrap: nowrap;
    position: fixed;
    flex-direction: row;
    cursor: default;

    //mobile screen
    @media only screen and (max-width: 600px) {
        display: none;
    }

    .selector {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 50%;
        height: calc(100vh - 60px);
        filter: brightness(0.7);
        font-size: 5rem;
        font-weight: 700;
        color: #F15A29;
        font-family: 'ITCAvantGarde', sans-serif;
        text-transform: capitalize;
        font-kerning: auto;
        letter-spacing: -5px;
        text-decoration: none;
        cursor: pointer;
        transition: flex 0.5s cubic-bezier(0.25, 0.68, 0.3, 0.98) 0.1s, color 0.2s ease-in-out, filter 0.2s ease-in-out;

        //small laptop screen
        @media only screen and (max-width: 1200px) {
            font-size: 4rem;
        }

        //tablet screen
        @media only screen and (max-width: 1000px) {
            font-size: 3.3rem;
        }

        .menu-reveal-container {
            text-decoration: none;
            color: white;
            font-size: 2rem;
            text-transform: none;
            //initially hidden
            opacity: 0;
            transition: all 0.2s ease-in-out;
            position: absolute;
            width: fit-content;
            justify-content: center;
            letter-spacing: 0px;

            //small laptop screen
            @media only screen and (max-width: 1200px) {
                font-size: 1.8rem;
            }

            //tablet screen
            @media only screen and (max-width: 1000px) {
                font-size: 1.5rem;
            }

            .menu-reveal {
                align-items: center;

                .menu-reveal-text {
                    display: hidden;
                    transition: opacity 0.2s ease-in-out, width 0.2s ease-in-out;
                }
            }

            &:hover {
                .menu-reveal {

                    .menu-reveal-text {
                        width: fit-content;
                        opacity: 1;
                    }
                }
            }
        }

        .top-container {
            top: 15%;
        }

        .bottom-container {
            bottom: 15%;
        }

        &:hover {
            flex: 58%;
            color: white;
            filter: brightness(1);
            transition: flex 0.5s cubic-bezier(0.25, 0.68, 0.3, 0.98) 0.1s, color 0.2s ease-in-out 0.2s, filter 0.2s ease-in-out 0.2s;

            .menu-reveal-container {
                opacity: 1;
                transition: all 0.2s ease-in-out 0.5s;
            }
        }
    }

    .selector-reveal {
        &::before {
            content: '';
            position: absolute;
            width: 1px;
            height: 0px;
            background-color: white;
            left: 50%;
            bottom: 60%;
            opacity: 0;
            transition: height 0.3s ease-in-out, opacity 0.2s ease-in-out;
        }

        &::after {
            content: '';
            position: absolute;
            width: 1px;
            height: 0px;
            background-color: white;
            left: 50%;
            top: 60%;
            opacity: 0;
            transition: height 0.3s ease-in, opacity 0.2s ease-in-out;
        }

        &:hover {
            &::before {
                opacity: 1;
                height: 16%;
                transition: height 0.3s ease-in 0.2s, opacity 0.2s ease-in-out 0.2s;
            }

            &::after {
                opacity: 1;
                height: 16%;
                transition: height 0.3s ease-in 0.2s, opacity 0.2s ease-in-out 0.2s;
            }
        }
    }
}
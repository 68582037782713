.pi-container {
    margin: calc(2 * var(--smallbar)) calc(var(--smallbar) + (0.5 * var(--bigbar)));
    position: relative;
    height: calc(2 * var(--bigbar));
    display: flex;
    max-width: 100vw;
    flex-wrap: wrap;

    @media screen and (max-width: 1000px) {
        margin: var(--smallbar);
        height: fit-content;

        .pi-items-container {
            position: relative;

            .pi-items-container {
                position: relative;
            }

            .overlay-left {
                display: none;
            }

            .overlay-right {
                display: none;
            }

            .overlay-top {
                display: none;
            }
        }
    }

    .pi-bg {
        position: absolute;
        width: 100%;
        height: 100%;
        background-position-y: 80%;
        background-repeat: no-repeat;
        background-size: cover;
        flex: 100%;

        @media screen and (max-width: 1000px) {
            position: relative;
            width: 100%;
            height: calc(1.5 * var(--bigbar));
        }
    }

    .pi-items-container {

        .overlay-left {
            position: absolute;
            left: var(--bigbar);
            height: 100%;
            width: calc(0.5 * var(--bigbar));
            background-color: white;
            bottom: 0;
        }

        .info-bar {
            position: absolute;
            top: 0;
            left: 0;
            width: calc(1.5 * var(--bigbar));
            background-color: white;
            font-size: 1.2rem;
            /* height: calc(1.2 * var(--bigbar)); */
            height: fit-content;
            min-height: 100%;
            z-index: 11;
            font-family: 'ITCAvantGarde', sans-serif;
            border: 1px solid rgba(142, 142, 142, 0.13);
            border-right: none;

            @media screen and (max-width: 1200px) {
                font-size: 1rem;
            }

            @media screen and (max-width: 1000px) {
                position: relative;
                flex: 100%;
            }

            &>* {
                padding: 12px;
            }

            .info-bar-title {
                font-size: 1.5rem;
                padding-bottom: 35px;
                margin-top: var(--smallbar);

                @media screen and (max-width: 1000px) {
                    padding-bottom: var(--smallbar);
                }
            }

            .info-item {
                padding-bottom: 20px;
                display: flex;
                align-items: center;

                &:first-child {
                    padding-top: 12px;
                }

                .info-icon {
                    padding-right: 5px;
                    display: flex;
                    align-items: center;
                }

                .info-description {}
            }
        }

        .map-container {
            position: absolute;
            bottom: 0;
            right: 0;
            border: white solid calc(0.5 * var(--smallbar));

            iframe {}
        }

        .overlay-right {
            position: absolute;
            bottom: 0;
            left: calc(1.5 * var(--bigbar));
            width: var(--bigbar);
            height: var(--smallbar);
            background-color: white;
        }

        .overlay-top {
            position: absolute;
            top: 0;
            right: 0;
            width: calc(3.5 * var(--bigbar) + 2px);
            height: var(--smallbar);
            background-color: white;
        }
    }
}
.article-container,
.editor-container {
  position: relative;
  background-color: white;
  @media screen and (max-width: 1000px) {
    margin-top: 24px;
  }
}

.article-content,
.ProseMirror {
  font-family: "akkurat";
  font-size: 1rem;
  line-height: 1.5;

  .highlighted-message {
    border: 1px solid #ef7f2f;
    background-color: #fafafa;
    padding: 10px 15px;
    margin: 16px 0;

    *:first-child {
      padding-top: 0;
      margin-top: 10px;
    }

    *:last-child {
      padding-bottom: 0;
      margin-bottom: 10px;
    }

    li {
      padding: 5px 0;
    }

    h3 {
      padding-bottom: 5px;
    }
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0d0d0d;
    color: #fff;
    font-family: "JetBrainsMono", monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  p {
    padding: 9px 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    padding: 14px 0;
  }

  li {
    margin-left: 15px;
  }

  ol {
    li {
      p {
        padding: 5px 0;
      }

      ol,
      ul {
        li,
        li > p {
          padding: 5px 0;
        }
      }
    }
  }

  mark {
    background-color: #ef7f2f;
    padding: 0 2px;
  }

  a {
    color: var(--orange);
    transition: all 0.5s cubic-bezier(0, 0.5, 0.5, 1);
    text-decoration: underline;

    &:hover {
      color: var(--maroon);
      text-decoration: underline;
    }
  }

  table {
    width: 100%;

    tr {
      border: 1px solid #e7e3e3;

      th {
        padding: 5px 10px;
        border: 1px solid #e7e3e3;
        background-color: #971a43;
        color: #f2f2f2;
      }

      td {
        padding: 5px 10px;
        border: 1px solid #e7e3e3;
      }
    }
  }

  /* .img-container {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
    flex-wrap: wrap;
 */
  img {
    //drop shadow
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    height: auto;
    max-width: 45%;
    flex: 1 1 45%;
    margin: 30px auto;
    display: block;

    &:only-child {
      max-width: 70%;
    }

    &.sponsor-image {
      margin: 0 auto;
      box-shadow: none;
      max-height: 300px;
    }

    @media screen and (max-width: 1000px) {
      max-width: 90%;
      //top and bottom margin 10px
      margin: 15px 0;

      //no margin top if first child and no margin bottom if last child
      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &:only-child {
        max-width: 90%;
      }

      &.sponsor-image {
        max-height: 200px;
        display: flex;
      }
    }
  }

  .sponsors-list {
    display: flex;
    flex-wrap: wrap;

    a {
      max-height: 200px;
      width: 50%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      padding: 20px 10px;
    }

    img {
      width: 100%;
      max-height: 200px;
      margin: 0 auto;
    }
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0d0d0d, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0d0d0d, 0.1);
    margin: 2rem 0;
  }
}

.article-container {
  flex: calc(100% * (2 / 3));
  z-index: 11;
  border-left: #f6f6f6 solid 1px;

  .image-container {
    text-align: center;
    max-height: 500px;
    overflow-y: hidden;
  }

  .html-container {
    width: 100%;
    padding: 2vw var(--bigbar) 5vw 2vw;
    background-color: white;
    min-height: 100vh;

    //on mobile 100% width
    @media (max-width: 1000px) {
      width: 100%;
      padding: 2vw 4vw 5vw 4vw;
      margin-right: 0;
    }
  }

  .editor-container {
    width: 100%;
    padding-right: var(--bigbar);
    max-width: 100%;
    word-wrap: break-word;

    //on mobile 100% width
    @media (max-width: 1000px) {
      width: 100%;
      padding: 2vw 4vw 5vw 4vw;
      margin-right: 0;
    }

    .ProseMirror {
      padding: 2vw;
      background-color: white;
      font-family: "akkurat";
      font-size: 1rem;
      line-height: 1.5;
      min-height: 100vh;
      word-wrap: break-word;
      word-break: break-word;
      max-width: 100%;
      overflow-x: hidden;
      white-space: pre-wrap;

      //on mobile 100% width
      @media (max-width: 1000px) {
        width: 100%;
        max-width: 100vw;
        padding: 2vw 4vw 5vw 4vw;
        margin-right: 0;
      }
    }
  }

  .loading-overlay {
    width: 0%;
    height: 100%;
    top: 0;
    position: absolute;
    background: #f6f6f6;
    z-index: 12;
    transition: all 0.5s cubic-bezier(0, 0.5, 0.5, 1);
    overflow: hidden;
  }

  .loading-overlay.loading {
    width: 100%;
    left: 0;
  }

  .loading-overlay.loaded {
    right: 0;
  }

  .loading-spinner {
    top: 20vw;
    left: 30.66vw;
    transform: translate(-50%, -50%);
    width: 80px;
    height: 2px;
    /* 
        background: #ccc; */
    position: absolute;
    overflow: hidden;
  }

  .loading-spinner::before {
    content: "";
    width: 80%;
    height: 100%;
    background: #971a43;
    position: absolute;
    top: 0;
    left: 0;
    animation: loop 1s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  }
}

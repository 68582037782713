* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.accordion-menu {

    .menu-items {
        position: relative;
        font-size: 1.3rem;
        color: rgb(30, 30, 30);
        //slide in from the top
        animation: slide-in 0.3s ease-in-out forwards;
        transform: translateY(-100%);
        text-transform: capitalize;
        font-family: 'ITCAvantGarde', sans-serif;

        @keyframes slide-in {
            to {
                transform: translateY(0);
            }
        }

        .menu-item {
            background-color: white;
            height: 80px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            //1px gray border
            border-bottom: 1px solid #e6e6e6;
            text-decoration: none;
            color: black;
            padding-left: 20px;
            text-transform: capitalize;
            font-size: 1.4rem;
            opacity: 0;
            font-family: 'ITCAvantGarde', sans-serif;
            transition: background-color 0.4s, color 0.4s;
            animation: fade-in 0.5s ease-in-out forwards;
            text-align: left;

            //after
            &:after {
                content: "\2192";
                position: relative;
                padding-right: 20px;
                opacity: 0;
                transition: transform 0.4s, opacity 0.4s;
                transform: translateX(-100%);
                color: #F15A29;
            }

            &:hover {
                background-color: #f2f2f2;
                color: #F15A29;

                &:after {
                    transform: translateX(0%);
                    opacity: 1;
                }
            }
        }

        a {
            display: block;
            font-size: inherit;
            color: inherit;
            text-decoration: none;
        }

        button {
            display: flex;
            align-items: center;
            color: inherit;
            font-size: inherit;
            border: none;
            background-color: transparent;
            cursor: pointer;
            width: 100%;
            text-transform: capitalize;
            font-family: 'ITCAvantGarde', sans-serif;
        }

        .dropdown {
            padding: 0 1rem;
            list-style: none;
            display: none;
            z-index: -1;
            overflow: hidden;
            border-bottom: 1px solid #e6e6e6;

            .menu-item {
                border-bottom: none;
            }

            &.show {
                display: block;
            }
        }
    }
}
.curtainsContainer {
    position: fixed;
    display: flex;
    width: 100vw;
    max-width: 100vw;
    height: calc(100vh - 58px);
    flex: 100%;
    flex-wrap: nowrap;
    pointer-events: none;
    z-index: 98;
    bottom: 0;

    .big {
        flex: 15.33%;
        border-left: rgba(142, 142, 142, 0.13) solid 1px;
        background-color: #fff;
    }

    .small {
        flex: 4%;
        max-width: 6%;
        border-left: rgba(142, 142, 142, 0.13) solid 1px;
        background-color: #fff;
    }

    .curtain {
        visibility: hidden;
        transform-origin: center top;
        background-color: #fff;
        --increment: 50ms;
        --delay-1: 0.33s;
        --delay-2: calc(var(--delay-1) + var(--increment));
        --delay-3: calc(var(--delay-2) + var(--increment));
        --delay-4: calc(var(--delay-3) + var(--increment));
        --delay-5: calc(var(--delay-4) + 30ms);
        --animation-duration: 0.65s;
        --total-length: calc(var(--delay-1) + var(--animation-duration));
        --easing-down: cubic-bezier(.445, .05, .55, .95);
        --easing-up: cubic-bezier(.445, .05, .55, .95);
        /* --easing-down: ease-out;
        --easing-up: ease-out; */
    }

    /* FORMAT OF ANIMATION:

        animation: 
        name_of_animation 
        duration_of_animation 
        ease/style
        delay_before_animation 
        
    */
    :nth-child(1) {
        border-left: none;
    }

    :nth-child(1),
    :nth-child(8) {
        animation: crescentDown var(--animation-duration) var(--easing-down) var(--delay-1), crescentUp calc(var(--animation-duration) + (var(--delay-5) - var(--delay-1))) var(--easing-up) var(--total-length);
    }

    :nth-child(2),
    :nth-child(7) {
        animation: crescentDown calc(var(--total-length) - var(--delay-2)) var(--easing-down) var(--delay-2), crescentUp calc(var(--animation-duration) + (var(--delay-5) - var(--delay-2))) var(--easing-up) var(--total-length);
    }

    :nth-child(3),
    :nth-child(6) {
        animation: crescentDown calc(var(--total-length) - var(--delay-3)) var(--easing-down) var(--delay-3), crescentUp calc(var(--animation-duration) + (var(--delay-5) - var(--delay-3))) var(--easing-up) var(--total-length);
    }

    :nth-child(4) {
        animation: crescentDown calc(var(--total-length) - var(--delay-4)) var(--easing-down) var(--delay-4), crescentUp calc(var(--animation-duration) + (var(--delay-5) - var(--delay-4))) var(--easing-up) var(--total-length);
    }

    :nth-child(5) {
        animation: crescentDown calc(var(--total-length) - var(--delay-5)) var(--easing-down) var(--delay-5), crescentUp var(--animation-duration) var(--easing-up) var(--total-length);
    }

    @media only screen and (max-width: 1000px) {
        :nth-child(2) {
            animation: crescentDown calc(var(--total-length) - var(--delay-4)) var(--easing-down) var(--delay-4), crescentUp calc(var(--animation-duration) + (var(--delay-5) - var(--delay-4))) var(--easing-up) var(--total-length);
        }

        :nth-child(1),
        :nth-child(8) {
            animation: crescentDown calc(var(--total-length) - var(--delay-3)) var(--easing-down) var(--delay-3), crescentUp calc(var(--animation-duration) + (var(--delay-5) - var(--delay-3))) var(--easing-up) var(--total-length);
        }

        :nth-child(7) {
            animation: crescentDown calc(var(--total-length) - var(--delay-5)) var(--easing-down) var(--delay-5), crescentUp var(--animation-duration) var(--easing-up) var(--total-length);
        }
    }
}

@keyframes crescentDown {

    0% {
        visibility: visible;
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(0%);
    }
}

@keyframes crescentUp {

    0% {
        visibility: visible;
        transform: translateY(0%);
    }

    100% {
        transform: translateY(100%);
    }
}

@media only screen and (max-width: 1000px) {
    .hide {
        display: none;
    }
}
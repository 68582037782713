.sidebar-container {
    height: calc(100vh - 60px);
    max-width: calc(2 * var(--bigbar));
    background-color: #fff;
    z-index: 11;
    --transform-const: calc(-0.5 * 17vh);
    transform: translateY(var(--transform-const));
    border-left: #f6f6f6 1px solid;
    font-family: 'ITCAvantGarde', sans-serif;
    position: sticky;
    //includes padding in top and bottom sticky
    top: calc(0px + 60px - var(--transform-const));
    overflow-y: scroll;
    overflow-x: hidden;

    //hide on mobile
    @media (max-width: 768px) {
        display: none;
    }

    .featured-content, .featured-item {
        padding: 4.2127435492vw 2.1063717746vw 4.2127435492vw 2.1063717746vw;
        border-bottom: 1px solid #f6f6f6;
        position: relative;
        justify-content: left;


        .list-header, .featured-header {
            padding-bottom: 25px;
        }

        .feature-title, .featured-item-title {
            font-size: 2.5rem;
            font-family: 'ITCAvantGarde', sans-serif;
            font-weight: bold;
        }

        .route-link {
            padding-top: 25px;
            margin-bottom: -20px;
        }

        .list-header,
        .feature-title, .route-link, .featured-item-title {
            translate: 0 30%;
            opacity: 0;
            animation: feature-load 1s ease-in-out forwards;
            animation-delay: 0.7s;
        }
    }

    .content-list {

        .list-item {
            padding: 2.1063717746vw 2.1063717746vw 2.1063717746vw 2.1063717746vw;
            border-bottom: 1px solid #f6f6f6;
            position: relative;
            cursor: pointer;
            transition: all 0.15s ease-in-out;

            &:hover {
                background-color: #f6f6f6;
            }

            .list-header {
                padding-bottom: 17px;
            }

            .list-item-title {
                font-family: 'ITCAvantGarde', sans-serif;
                font-weight: bold;
                color: black;
            }
        }
    }
}

.list-item-date, .featured-item-date {
    font-size: 0.8rem;
    color: gray;
}

.list-item-category, .featured-item-category {
    font-size: 0.8rem;
    letter-spacing: 1px;
    color: #971a43;
    text-transform: uppercase;
    padding-left: 5%;
}

.list-item>*, .featured-item>* {
    pointer-events: none;
}

@keyframes feature-load {

    80% {
        translate: 0 0;
    }

    100% {
        opacity: 1;
        translate: 0 0;
    }
}

@keyframes height-setter {
    to {
        height: fit-content;
    }
}
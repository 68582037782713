@media only screen and (max-width: 1000px) {}

.header {
    opacity: 1;
    position: fixed;
    top: 0;
    z-index: 99;
    background-color: white;
    width: 100%;
    box-sizing: border-box;
    /* animation: menu-reveal 0.6s ease-in-out forwards;
    animation-delay: 3.4s; */
    box-shadow: 0px 1px 3px rgb(156, 156, 156);
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;

    .linkContainer {
        display: flex;
        flex: 100%;
    }
}

.mobile-header {
    display: flex;
    justify-content: space-between;
    padding: 0 10px;

    .mobile-menu {

        &>* {
            font-size: 50px;
        }
    }
}

.mobile-menu-container {
    position: fixed;
    top: 60px;
    z-index: 98;
    width: 100%;
    max-height: calc(100vh - 60px);
    background-color: white;
    overflow-y: scroll;

    //slide in from the top
    animation: mobile-slide-in 0.5s ease-in-out forwards;
    transform: translateY(-100%);

    &.closing {
        animation: mobile-slide-out 0.5s ease-in-out forwards;
        transform: translateY(0);
    }

    @keyframes mobile-slide-in {
        to {
            transform: translateY(0);
        }
    }

    @keyframes mobile-slide-out {
        to {
            transform: translateY(-100%);
        }
    }
}

.desktop-header {
    display: flex;
    justify-content: center;

    .link-container {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        margin: 0 calc((0.5 * var(--bigbar)) + var(--smallbar));

        .header-link {
            flex: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            box-sizing: content-box;

            .header-link-text {
                font-family: 'ITCAvantGarde', sans-serif;
                text-decoration: none;
                font-size: 0.7rem;
                letter-spacing: 2px;
                color: black;
                transition: 0.4s;
                width: auto;
                height: auto;
                text-transform: uppercase;
                cursor: pointer;

                &:hover {
                    color: #F15A29;
                }
            }
        }
    }
}

.about-menu {
    position: fixed;
    top: 60px;
    z-index: 98;
    width: calc(100% - 2 * var(--smallbar));
    height: fit-content;
    background-color: white;
    padding: 0 calc(var(--smallbar) / 2);
    animation: slide-in 0.5s ease-out forwards;
    transform: translateY(-100%);
    display: flex;
    flex-wrap: wrap;
    //center on page
    left: var(--smallbar);

    &.closing {
        animation: slide-out 0.5s ease-out forwards;
        transform: translateY(0);
    }

    .about-link {

        background-color: white;
        flex: 50%;
        height: 100px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 50%;
        //1px gray border
        border-bottom: 1px solid #e6e6e6;
        border-right: 1px solid #e6e6e6;
        text-decoration: none;
        color: black;
        padding-left: 20px;
        text-transform: capitalize;
        font-size: 1.4rem;
        opacity: 0;
        font-family: 'ITCAvantGarde', sans-serif;
        transition: background-color 0.4s, color 0.4s;
        animation: fade-in 0.5s ease-in-out forwards;

        &.closing {
            opacity: 1;
        }

        @for $i from 1 through 6 {
            &:nth-child(#{$i}) {
                animation-delay: (0.1s * $i) + 0.15s;
            }
        }

        @keyframes fade-in {
            to {
                opacity: 1;
                transform: translateY(0%);
            }
        }

        &:nth-child(odd) {
            border-left: 1px solid #e6e6e6;
        }

        //after
        &:after {
            content: "\2192";
            position: relative;
            padding-right: 20px;
            opacity: 0;
            transition: transform 0.4s, opacity 0.4s;
            transform: translateX(-100%);
            color: #F15A29;
        }

        &:hover {
            background-color: #f2f2f2;
            color: #F15A29;

            &:after {
                transform: translateX(0%);
                opacity: 1;
            }
        }
    }

    @keyframes slide-in {
        to {
            transform: translateY(0);
        }
    }

    @keyframes slide-out {
        to {
            transform: translateY(-100%);
            filter: brightness(0.8);
        }
    }
}

.darken-page {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    /* holy shit copilot picked the right z-index */
    z-index: 97;
    animation: darken 0.5s ease-in-out forwards;

    &.closing {
        animation: lighten 0.5s ease-in-out forwards;
    }

    @keyframes darken {
        to {
            background-color: rgba(0, 0, 0, 0.5);
        }
    }

    @keyframes lighten {
        from {
            background-color: rgba(0, 0, 0, 0.5);
        }

        to {
            background-color: rgba(0, 0, 0, 0);
        }
    }
}
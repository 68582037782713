.gi-container {
    margin: calc(2 * var(--smallbar)) calc(var(--smallbar) + (0.5 * var(--bigbar)));
    max-width: 100vw;
    overflow: hidden;

    @media screen and (max-width: 1000px) {
        margin: var(--smallbar);

        .gi-top-container {

            .gi-bg {
                position: relative;
                width: 100%;
                background-position-x: center;
                background-repeat: no-repeat;
                background-size: cover;
            }

            .gi-title-container {
                position: relative;

                .overlay-left {
                    display: none;
                }

                .overlay-right {
                    display: none;
                }

                .overlay-top {
                    display: none;
                }
            }
        }
    }

    .gi-top-container {
        position: relative;
        height: calc(2.5 * var(--bigbar));

        @media screen and (max-width: 1000px) {
            height: calc(1.5 * var(--bigbar));
        }

        .gi-bg {
            position: absolute;
            width: 100%;
            height: 100%;
            background-position-y: 35%;
            background-repeat: no-repeat;
            background-size: cover;
        }

        .gi-title-container {
            position: absolute;
            bottom: 0;
            width: inherit;
            height: inherit;

            .overlay-left {
                width: calc(var(--bigbar) * 1.5);
                height: calc(2 * var(--smallbar));
                background-color: white;
                position: absolute;
                bottom: 0;

                @media screen and (max-width: 1400px) {
                    height: calc(1.5 * var(--smallbar));
                }

                @media screen and (max-width: 1150px) {
                    height: calc(var(--smallbar) * 1);
                }
            }

            .gi-title {
                position: absolute;
                bottom: 0;
                left: calc(var(--bigbar) + var(--smallbar));
                width: calc(var(--bigbar) * 2.5);
                height: calc(var(--smallbar) * 3);
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: white;
                font-family: 'ITCAvantGarde', sans-serif;
                font-size: 3rem;
                z-index: 11;

                @media screen and (max-width: 1400px) {
                    font-size: 2.5rem;
                    height: calc(var(--smallbar) * 2.5);
                }

                @media screen and (max-width: 1150px) {
                    font-size: 2rem;
                    height: calc(var(--smallbar) * 2);
                }

                @media screen and (max-width: 1000px) {
                    display: none;
                }
            }

            .overlay-right {
                width: var(--smallbar);
                height: 100%;
                background-color: white;
                position: absolute;
                left: calc((3.5 * var(--bigbar)) + var(--smallbar));
            }

            .overlay-top {
                width: calc(var(--bigbar) + 2px);
                height: var(--smallbar);
                background-color: white;
                position: absolute;
                top: 0;
                left: calc(4 * var(--bigbar));
            }
        }
    }

    .gi-description {
        font-family: akkurat, sans-serif;
        font-size: 1.4rem;
        line-height: 1.5;
        margin: 0 calc((0.5 * var(--bigbar)) + 1px);
        margin-top: -3rem;
        z-index: 11;
        position: relative;
        border-bottom: rgba(142, 142, 142, 0.13) solid 1px;

        @media screen and (max-width: 1400px) {
            font-size: 1.2rem;
            margin-top: -1rem;
        }

        @media screen and (max-width: 1000px) {
            width: 100%;
            margin: 0;
            overflow: hidden;
        }

        h1 {
            display: none;

            @media screen and (max-width: 1000px) {
                display: block;
                font-family: 'ITCAvantGarde', sans-serif;
                font-size: 1.8rem;
                background-color: white;
                padding-top: 1rem;
                padding-bottom: 1rem;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        p {
            padding-bottom: 1rem;
            padding-right: 1rem;
            padding-left: 1rem;
            background-color: white;

            &:nth-child(2) {
                padding-top: 1rem;
                margin-right: calc(0.5 * var(--bigbar));

                @media screen and (max-width: 1000px) {
                    width: 100%;
                    margin: 0;
                }
            }
        }
    }
}
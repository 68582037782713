// Use :root to declare global variables
:root {
  --bigbar: 15.33vw;
  --smallbar: 4vw;
  /* --standard-margins: var(--smallbar); */

  //mobile
  @media (max-width: 1000px) {
    --smallbar: 6vw;
    --bigbar: calc((100vw - (2 * var(--smallbar))) / 2);
  }

  --maroon: #971a43;
  --orange: #ff7f50;

}

html {
  width: 100vw;
  overflow-x: hidden;
  max-width: 100vw;

  a {
    color: var(--orange);
    transition: all 0.5s cubic-bezier(0, 0.5, 0.5, 1);
    text-decoration: none;

    &:hover {
      color: var(--maroon);
      text-decoration: underline;
    }
  }
}

.material-symbols-outlined {
  font-variation-settings:
    'FILL' 0,
    'wght' 400,
    'GRAD' 0,
    'opsz' 48
}

.App {
  text-align: center;
  scrollbar-gutter: stable both-edges;
  font-kerning: normal;
}

//declares the font family ITCAvantGardeBold
@font-face {
  font-family: 'ITCAvantGarde';
  src: url('./assets/fonts/itc__avant__garde_bold-webfont.woff') format('woff2'),
    url('./assets/fonts/itc__avant__garde_bold-webfont.woff2') format('woff');
  font-weight: bold;
  font-style: normal;
}

//declares the font family ITCAvantGardePro
@font-face {
  font-family: 'ITCAvantGarde';
  src: url('./assets/fonts/ITCAvantGardePro-Md.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'akkurat';
  src: url('./assets/fonts/akkurat-light-webfont.woff2') format('woff2'),
    url('./assets/fonts/akkurat-light-webfont.woff') format('woff');
  font-weight: lighter;
  font-style: normal;

}

@font-face {
  font-family: 'akkurat';
  src: url('./assets/fonts/akkurat-bold-webfont.woff2') format('woff2'),
    url('./assets/fonts/akkurat-bold-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;

}

@font-face {
  font-family: 'akkurat';
  src: url('./assets/fonts/akkuratpro-regular-webfont.woff2') format('woff2'),
    url('./assets/fonts/akkuratpro-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

/* 
//on webkit browsers, custom scrollbar
//width
::-webkit-scrollbar {
  width: 10px;
}

//track
::-webkit-scrollbar-track {
  background: transparent; 
}
 
//handle
::-webkit-scrollbar-thumb {
  background: #e5e5e5; 
}

//handle on hover
::-webkit-scrollbar-thumb:hover {
  background: rgb(212, 212, 212); 
}
 */

//disable scrollbar on firefox
* {
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* IE 10+ */
}

/* //disable scrollbar on chrome
*::-webkit-scrollbar {
  display: none;
} */

::-webkit-scrollbar {
  width: 3px;
  background-color: lightgray;
}

::-webkit-scrollbar-thumb {
  background-color: gray;
}

//changes the color of highlighted text
::selection {
  background: orange;
  color: white;
}

//temp
.page-loading-screen {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;

  .spinner-container {


    --spinner-size: 150px;

    .spinner-logo {
      width: var(--spinner-size);
      height: var(--spinner-size);
      background: url('https://i.imgur.com/uF93d94.png') no-repeat center center;
      background-size: contain;
      opacity: 0.9;
      filter: drop-shadow(0 0 2px #ccc);
      filter: grayscale(10%);
      translate: 8px 0px;
    }

    .spinner-line {
      width: var(--spinner-size);
      height: 2px;
      background: #ccc;
      position: relative;
      overflow: hidden;
    }

    .spinner-line::before {
      content: "";
      width: 80%;
      height: 100%;
      background: #971a43;
      position: absolute;
      top: 0;
      left: 0;
      animation: loop 1.5s cubic-bezier(0, 0.5, 0.5, 1) infinite;
    }

    @keyframes loop {
      0% {
        transform: translateX(-100%);
      }

      100% {
        transform: translateX(120%);
      }
    }
  }
}
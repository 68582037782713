.page-content {
    margin: 0 var(--smallbar);
    display: flex;
    height: fit-content;
    position: relative;

    &>* {
        transform: translateY(calc(-0.5 * 17vh));
        margin-bottom: calc(-0.5 * 17vh);
    }
}
.page-header {
    width: 100%;
    height: 80vh;
    position: relative;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    overflow: hidden;

    @media screen and (max-width: 1000px) {
        height: 40vh;
        margin-top: 60px;
    }

    .header-content {
        width: 100%;
        position: absolute;
        top: 40%;
        text-align: center;
        font-family: 'ITCAvantGarde', sans-serif;
        font-weight: bold;

        .page-subtitle {
            color: rgba(255, 255, 255, .8);
            font-size: 2rem;
            letter-spacing: 1px;
            margin-bottom: 1.042390549vw;
        }

        .page-title {
            color: #fff;
            font-size: 5rem;
        }

        @media screen and (max-width: 768px) {
            .page-subtitle {
                font-size: 1.5rem;
            }

            .page-title {
                font-size: 2rem;
            }
        }
    }
}
.topbar-container {
    position: relative;
    margin: 0 var(--smallbar);
    height: 17vh;
    z-index: 12;
    background-color: #971a43;
    translate: 0;
    animation: slideUp 1s ease-in-out forwards;
    display: flex;
    flex-wrap: nowrap;
    text-align: center;

    @media screen and (max-width: 768px) {
        margin: 0;
        height: 10vh;
    }

    .filter-dropdown {
        flex: 100%;
        background-color: #971a43;
        border: none;
        position: inherit;
        font-size: 2rem;
        color: white;
        text-transform: capitalize;
        font-family: 'ITCAvantGarde', sans-serif;
        max-width: calc(2 * var(--bigbar));
        text-align: center;

        @media screen and (max-width: 768px) {
            max-width: 100%;
            font-size: 1rem;
            padding-left: var(--smallbar);
            padding-right: var(--smallbar);
        }

        &:focus {
            outline: none;
        }

        .filter {
            text-align: left;
            width: 100%;
            height: 100%;
            position: absolute;
        }
    }
}

@keyframes slideUp {
    to {
        translate: 0 -50%;
    }
}
.banner {
    display: flex;
    height: 60px;
    max-width: 100vw;
    width: 100vw;
    z-index: 97;
    position: relative;
    background-color: rgb(33, 33, 59);
    color: white;
    font-size: 14px;
    //center the text
    justify-content: center;
    align-items: center;
    //box shadow
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
    transform: translateY(-100%);
    margin-bottom: -60px;
    animation: slide-down 1s ease-in-out forwards;
    animation-delay: 3.2s;

    //children padding
    &>* {
        padding: 0 10px;

        @media screen and (max-width: 1000px) {
            padding: 0 5px;
        }
    }

    .banner-text {
        display: inline-block;
        text-align: center;

        @media screen and (max-width: 1300px) {
            font-size: 1rem;
        }

        @media screen and (max-width: 1000px) {
            font-size: 1.2rem;
            order: 0;
        }
    }

    .banner-link {
        text-decoration: none;

        .banner-button {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: white;
            border: none;
            color: #5e5e5e;
            padding: 5px 10px;
            cursor: pointer;
            transition: all 0.3s ease-in-out;
            box-sizing: border-box;
            height: 40px;

            &:hover {
                background-color: #e9e9e9;
            }
        }

        @media screen and (max-width: 1000px) {
            order: 2;
        }
    }

    .banner-countdown {
        order: 1;
        display: flex;

        

        .banner-countdown-item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 0 3px;

            .banner-countdown-number {
                font-size: 20px;
                font-weight: 700;
                border: 2px solid white;
                border-radius: 5px;
                padding: 0 10px;
            }

            .banner-countdown-text {
                font-size: 12px;
                font-weight: 400;
            }
        }

        .banner-days {

            @media screen and (max-width: 1000px) {
                display: none;
            }
        }
    }
}


@keyframes slide-down {
    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(0);
        margin-bottom: 0;
    }
}